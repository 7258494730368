<script lang="ts">
	import Head from "$myLib/components/Head.svelte";
	import { KnownImages } from "$myLib/helpers/KnownImages";
	import { PriceList } from "$myLib/constants/Terms";
	import PriceListComponent from "$myLib/components/PriceList.svelte";

	const features = [
		{
			title: "Dein-Ticket.Shop",
			subtitle: "Ticketing einfach gemacht",
			icon1: "heart",
			icon2: "thumbs-up",
			features: ["Die All-In-One-Lösung für Deinen Ticketverkauf", "Browser-basierte Online-Plattform", "Einfache Benutzerführung", "Individualisierte Veranstalter-Darstellung auf der Online-Plattform (mit Name, Beschreibung, News, Logo, Favicon etc.)", "Vollständige Zugriffsrechte nach einmaliger Registrierung auf Dein-Ticket.Shop", "Kompetenter Support (telefonisch/mail)", "Hoher Datenschutz, keine Speicherung unnötiger Daten", "Unterstützung beliebig vieler Benutzer für den Veranstalter-Zugang", "Unterstützung verschiedener Benutzerrollen mit variablen Lese- und Schreibrechten", "transparente und günstige Plattformgebühren (5% des Ticketumsatzes)", "Auf Wunsch zusätzliche Dienstleistung (individuelle Veranstalterwünsche, Zusatzfunktionen oder Schnittstellen)"],
		},
		{
			title: "Zum Vorverkauf in 5 Minuten",
			subtitle: "Veranstaltungen selbst anlegen",
			icon1: "cart-shopping",
			icon2: "euro-sign",
			features: ["Anlage der Veranstaltungen durch den Veranstalter mit allen relevanten Infos (Titel, Datum, Uhrzeiten, Ort, Saalplan, Eintrittspreis etc.)", "Einbindung von Pressetext (inkl. Dateianhängen) & Pressebild", "Integrierter Bildeditor zur Anpassung der Pressebilder auf das ideale Format", "Anmeldungs-Tool: die Alternative zum VVK für kostenlose Angebote", "Gliederung der Veranstaltungen nach Veranstaltungsreihen", "Copy-Paste-Funktion: Duplizieren und Bearbeiten wiederkehrender Veranstaltungen und Veranstaltungsreihen", "Verlinkte Veranstaltungen: Auflistung einer Veranstaltung bei mehreren Veranstaltern einer Kooperationsveranstaltung"],
		},
		{
			title: "Flexibel bleiben",
			subtitle: "Mit dem Saalplan-Editor *",
			icon1: "users",
			icon2: "chair",
			features: ["Erstellung von individuellen Saalplänen durch den Veranstalter", "Saalpläne mit Platzzuweisung oder freier Platzwahl, stehend oder sitzend, möglich", "Erstellung unterschiedlicher Preiskategorien", "Einbindung verschiedener grafischen Elemente, Tische etc.", "Funktion Rollstuhlplätze: Definieren oder ändern von Rollstuhlplätzen im Saalplan (inkl. Begleitperson)"],
		},
		{
			title: "Dein Ticket",
			subtitle: "so individuell und einzigartig wie Du *",
			icon1: "ticket-simple",
			icon2: "masks-theater",
			features: ["Ticket-Editor: Individualisiertes Ticketlayout", "Einbindung von Sponsorenlogos", "Integrierung eigener grafischer Elemente und Bilddateien", "Flexible Schriftarten und Textinhalte", "Angepasste Ticketformate je nach verwendetem Endgerät (DIN A4, Bon-Drucker, Ticketdrucker, Handy/Wallet)"],
		},
		{
			title: "Alle Wege führen nach Rom",
			subtitle: "und zu Deinem Ticket",
			icon1: "road",
			icon2: "signs-post",
			features: [
				"Zustellung von Print@home Tickets bei Onlineverkauf via Email.",
				"Unterstützung von Apple-Wallet und Google-Wallet",
				"Freikarten-Funktion: Ausdruck oder E-Mail-Versand beliebig vieler Veranstalter-Freikarten",
				"Early-Bird-Funktion: verbilligte Tickets zum VVK-Beginn bis zu einem gewünschten Datum oder einer gewünschten Anzahl",
				"Rabattfunktion für Personengruppen (bspw. Auszubildende, Renenter etc.)",
				"Mengenrabatt-Funktion: Rabatt ab einer bestimmten Anzahl von Tickets für eine oder explizit unterschiedliche Veranstaltungen",
				"Gutschein-Codes: Erstellung einmalig oder mehrfach gültiger Codes für ermäßigte oder kostenlose Karten",
				"Reservierungsfunktion (u.a. für Ehrengäste, Presse, Techniker etc.)",
				"Wahlweise Abfrage weiterer Informationen (Text, Zahl, Checkbox etc.) beim Ticketkauf (z.B. für Angabe Postleitzahl, Newsletterversand, Name bei Personenbindung etc.)",
				"Wahl der akzeptierten Zahlungsmethoden durch den Veranstalter (z.B. Kreditkarte, SEPA-Lastschrift, Klarna)",
				"Email-Kontaktliste aller Besucher als Excel-Download zum Versand einer Infomail (bspw. bei kurzfristigen Änderungen, Einlassbedingungen, Wetterhinweise etc.)",
				"Newsletter-Funktion: Auflistung aller für die Newsletter-Verwendung autorisierten E-Mail-Adressen",
			],
		},
		{
			title: "Gemeinsam geht mehr!",
			subtitle: "Anbindung von Vorverkaufsstellen",
			icon1: "shop",
			icon2: "cash-register",
			features: ["Einfache Einrichtung einer Veranstalter-VVK-Stelle (Voraussetzung lediglich PC, DIN A4 Drucker, Internet)", "Auf Wunsch Bereitstellung eines VVK-Systems (Inkl. Tablet, Ticketdrucker, Kartenlesegerät)", "Anbindung externer VVK-Stellen durch den Veranstalter (Voraussetzung lediglich PC, DIN A4 Drucker, Internet)", "Keine festgelegten Konditionen für VVK-Stellen (freie Vereinbarung zwischen Veranstalter und VVK-Stelle, prozentual oder Festbetrag)", "Notizfunktion an VVK-Stellen pro Veranstaltung und Veranstaltungsreihe (z.B. Ansprechpartner/Kontaktdaten, Rabattinformationen, Sonderaktionen, Stornierungskonditionen etc.)"],
		},
		{
			title: "Wenn's mal wieder anders läuft",
			subtitle: "die Konzertabsage",
			icon1: "cloud-rain",
			icon2: "umbrella",
			features: ["Konzertabsage-Tool: automatisierte Rückabwicklung aller Onlinekäufe mit nur einem Klick", "Wahlweise Übernahme der Transaktionskosten durch den Veranstalter oder Endkunden", "Benachrichtigung aller Kartenkäufer via E-Mail", "Wahl eines Ersatztermins bei Verschiebung", "Stornierung einzelner Tickets oder Gesamtbestellungen inkl. Email-Versand und Erstattung"],
		},
		{
			title: "Schneller Endspurt",
			subtitle: "Einlass & Abendkasse",
			icon1: "person-shelter",
			icon2: "stamp",
			features: ["Ticketscan-App für Android und IOS (inkl. Anzeige von Mehrfachverwendung und aktueller Auslastung*)", "Auf Wunsch Bereitstellung von Ticketscannern (PDA)", "QR-Code zum einfachen Login für Ticketscanner oder Abendkassensysteme", "Übersichtliche Abendkassen-Benutzeroberfläche (mit Saalplan, Mehrfach-Ticketauswahl, Zahlungsweise etc.)", "Gästelistefunktion für Abendkassen (nach Namen inkl. Vorauswahl als Freikarte oder verfügbarer Preiskategorien)", "Verkauf Zusatztickets: unabhängig vom Saalplan, frei in Preis und Anzahl*", "Auf Wunsch Bereitstellung von Abendkassensystemen (Inkl. Tablet, Ticketdrucker, Kartenlesegerät)", "KassenSichV- und DSFinV-K Konform (TSE)"],
		},
		{
			title: "Zahlen, die Spaß machen",
			subtitle: "Buchhaltung einfach gemacht",
			icon1: "database",
			icon2: "arrow-up-right-dots",
			features: ["Automatisierte Abwicklung aller Zahlungen im Rahmen des Vorverkaufs bequem und zentral über Dein-Ticket.Shop", "Automatisierter Versand eines nach deutschem Steuerrecht gültigen Belegs (z.B. Rechnung, Gutschrift) via E-Mail an den Veranstalter nach jeder getätigten oder empfangenen Zahlung", "Auszahlung bestehender Online-Kartenumsätze jederzeit durch den Veranstalter (und/oder regelmäßig in variabel wählbarem Zeitintervall*)", "Auszahlung von Kartenumsätzen der VVK-Stellen an den Veranstalter jederzeit möglich (und/oder regelmäßig in variabel wählbarem Zeitintervall*)", "Berücksichtigung aller Umsatzsteuersätze (7%, 19%, Steuerbefreiung)"],
		},
		{
			title: "Wir bringen Licht ins Dunkel",
			subtitle: "Alles Wesentliche auf einen Blick",
			icon1: "lightbulb",
			icon2: "file",
			features: [
				"Ticketverkaufsübersicht: Jederzeit aktuell und übersichtlich nach Verkaufszahlen, Auslastung, Umsatz etc. (auch als Excel-Download)",
				"Grafische Darstellung des Ticketverkaufs nach zeitlichem Verlauf (inkl. Filterfunktion einzelner Vernstaltungen und kumulativer Darstellung)",
				"Chronologische Bestellübersicht aller Ticketbestellung mit Name, Email, Veranstaltung etc. inkl. Suchfunktion",
				"Übersichtlicher Abschlussrapport pro Veranstaltung (inkl. Ausplittung nach Platzkategorien/Ermäßigungen, Verkaufsarten (Online, VVK-Stellen), der jeweiligen Gebühren, bar/elektronisch sowie brutto/netto",
				"Übersichtlicher Abschlussrapport einer Veranstaltungsreihe (nach Veranstaltung, Ticketanzahl und -Umsatz, Freikarten, Barzahlung, elektronische Zahlung, Gebühren und Übersicht aller entsprechenden Teilzahlungen",
				"Übersicht „GEMA“ für Veranstaltungsreihen (inkl. Datum, Veranstaltungsname und -Ort, Besucheranzahl und Ticketumsätze brutto / netto)",
				"Übersicht der Ticketumsätze und -zahlen aller VVK-Stellen",
				"Übersicht als PDF über Bareinnahmen der jeweiligen Abendkassen",
			],
		},
	];
</script>

<Head title="Über uns" description="Dein-Ticket.Shop ist eine Ticketing-Plattform für Veranstalter und Ticketkäufer" />

<div class="position-relative overflow-hidden p-3 p-md-5 text-white text-center bg-image head-container align-items-center d-flex" style="background-image: url('{KnownImages.GENERIC_EVENT_2}')">
	<div class="col-md-12 p-lg-5 my-5">
		<div class="bg-warning p-5 rounded-5 text-dark logocard mx-auto">
			<div class="flex-shrink-0">
				<img class="img-fluid" src={KnownImages.LOGO_FULL_COLOR_TRANSPARENT} alt="Logo" style="max-height: 10rem;" />
				<p class="fw-bold fs-4 mt-2">Ticketing leicht gemacht!</p>
			</div>
		</div>
	</div>
</div>

<section class="my-5 py-5 container text-center mw-50 lead">
	<p>Gleich vorab: <b>Dein-Ticket.Shop</b> ist alles andere als ein Riese unter den Ticketanbietern. Als David unter vielen Goliaths legen wir das Hauptaugenmerk darauf, Dein Ticketing mit einer Vielzahl an Funktionen so bequem aber auch so einfach, effizient und zeitsparend als möglich zu machen! Davon profitieren nicht nur - aber vor allem - kleine und mittelgroße Kulturveranstalter.</p>

	<p>Als All-in-one Lösung haben wir den gesamten Abwicklungs-Prozess optimiert: Vom Anlegen der Veranstaltungen und dem Verkauf der Tickets (online und über VVK-Stellen) über eine schnelle Durchführung beim Einlass und an der Abendkasse bis hin zur vollautomatisierten Zahlungsabwicklung und Abrechnung.</p>
	<p>Was Dir und uns außerdem wichtig ist: Ein kompetenter und schneller Support und ein sorgsamer Umgang mit allen Daten. So einfach das gesamte Handling auf <b>Dein-Ticket.Shop</b> ist, so einfach, günstig und transparent sind auch die Plattform-Gebühren: 5% des Ticketumsatzes.</p>

	<p>Unsere Funktionen für Deine erfolgreiche Veranstaltung sind das Ergebnis kontinuierlicher Weiterentwicklung auf Basis unseres Kundenfeedbacks. Wir hören unseren Veranstaltern zu und arbeiten auch weiterhin hart daran die besten Tools bereitzustellen um Deine Veranstaltungen noch erfolgreicher zu gestalten.</p>

	<p><b>Interessiert…?</b> Dann werfe einen Blick in unsere Funktionsübersicht:</p>
</section>

<div class="container-fluid bg-image py-5" style="background-image: url('{KnownImages.GENERIC_EVENT_2}')">
	<section class="py-5 container">
		{#each features as feature}
			<div class="row row-cols-md-2 g-5 my-2">
				<div>
					<div class="col position-sticky" style="top: 150px;">
						<div class="card border-0 h-100 rounded-4">
							<div class="card-body text-center">
								<h3 class="card-title">{feature.title}</h3>
								<p class="card-text">{feature.subtitle}</p>
								<div class="position-relative">
									<i class="text-muted fas fa-{feature.icon1} fa-5x my-3 opacity-25"></i>
									<i class="text-dark position-absolute fas fa-{feature.icon2} fa-3x opacity-50 translate-secondary-icon" style="top: 50%; left: 50%;"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="card border-0 h-100 rounded-4">
						<div class="card-body text-start">
							<ul class="list-unstyled">
								{#each feature.features as subFeature}
									<li class="mb-3 d-flex align-items-center">
										<i class="text-warning fas fa-check-circle fa-lg me-3"></i>
										<span>{subFeature}</span>
									</li>
								{/each}
							</ul>
						</div>
					</div>
				</div>
			</div>
		{/each}
	</section>
</div>

<section class="text-center lead text-light bg-image py-5" style="background-image: url('{KnownImages.GENERIC_EVENT_2}')">
	<div class="py-5 container">
		<div class="row text-center justify-content-center">
			<div class="col" style="max-width: 400px;">
				<div class="card mb-4 rounded-3 shadow-sm border-warning h-100">
					<div class="card-header py-3 text-bg-warning border-warning">
						<h4 class="my-0 fw-normal">Konditionen</h4>
					</div>
					<div class="card-body justify-content-between d-flex flex-column">
						<div>
							<h1 class="card-title pricing-card-title mb-0">5 %</h1>
							<small class=" fw-light">zzgl. USt.<br />zzgl. Transaktionsgebühren</small>
						</div>
						<ul class="list-unstyled mt-3 mb-4">
							<li>unbegrenzte Tickets</li>
							<li>unbegrenzte Veranstaltungen</li>
							<li>kostenlose Freikarten</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="bg-dark">
	<div class="container text-light fs-6 py-5 text-start">
		<details>
			<summary>Detaillierte Preisliste und Transaktionsgebühren</summary>
			<PriceListComponent pricelist={PriceList} showHeader={false} width="75" />
		</details>
		<small class="d-block mt-3">{PriceList.footer}<br />Irrtümer und Änderungen vorbehalten.</small>
	</div>
</section>

<style lang="scss">
	.logocard {
		max-width: 500px;
	}

	.translate-secondary-icon {
		transform: translate(50%, 0%);
	}

	.bg-image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-blend-mode: multiply;
		background-color: rgba(0, 0, 0, 0.5);
		background-attachment: fixed;
	}

	.mw-50 {
		max-width: 70%;
	}
	@media (max-width: 992px) {
		.mw-50 {
			max-width: 100%;
		}
	}

	.card {
		transition: transform 0.2s ease-in-out;
		i {
			transition: opacity 0.2s ease-in-out;
		}
	}
	.card:hover {
		transform: scale(1.05);
		i {
			opacity: 1 !important;
		}
	}

	.head-container {
		min-height: 80vh;
	}
</style>
