<script lang="ts">
	import type { IPriceList } from "../constants/Terms";

	export let pricelist: IPriceList;
	export let showHeader = true;
	export let width: "50" | "75" | "100" = "50";
</script>

{#if showHeader}
	<h5>{pricelist.title}</h5>
{/if}
<ol class="list-style-position-inside ps-0">
	{#each pricelist.body as block}
		<li class="mt-4">
			<span>{block.title}</span>
			<div class="ms-3">
				{#each block.data as data}
					<h6 class="mt-3">{data.title}</h6>
					<table class="w-{width}">
						{#each data.body as line}
							<tr>
								<td class="w-50">{line.title}</td>
								<td class="w-50 text-end">{line.price}</td>
							</tr>
						{/each}
					</table>
				{/each}
			</div>
		</li>
	{/each}
</ol>

<style>
	.list-style-position-inside {
		list-style-position: inside;
	}
</style>
